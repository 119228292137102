<script lang="ts" setup>
import dayjs from "dayjs";

import type { TournamentData } from "@/types";

const props = defineProps<{
	race: TournamentData;
	isSweepStakes?: boolean;
}>();
defineEmits<{ (event: "click"): void }>();

const prepareImgUrl = useImage();
const { t } = useT();
const { durationLeft, duration, reset } = useCountdown({
	timestamp: props.race.end || "",
	formatToken: "HH[h ]mm[m ]ss[s ]"
});

watch(
	() => props.race?.historyId,
	() => {
		reset(props.race.end || "");
	}
);
</script>
<template>
	<div :class="['activity-race', { 'is-entries': isSweepStakes }]" @click="$emit('click')">
		<picture>
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/races/race-mob@2x.png', {
						format: 'avif',
						width: 320,
						height: 290
					})
				"
				media="(max-width: 767px)"
			/>
			<NuxtImg class="image" src="/nuxt-img/races/race.png" format="avif" width="326" height="340" alt="races" />
		</picture>

		<ABadge variant="skew" :background="isSweepStakes ? 'var(--cixi)' : 'var(--coimbatore)'" autosize>
			<AText variant="topeka" :modifiers="['bold', 'uppercase']">
				{{ isSweepStakes ? t("entries races") : t("coins races") }}
			</AText>
		</ABadge>

		<div class="prize-pool">
			<AText class="label text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Prize pool") }}</AText>
			<MPrizeFund
				:variant="isSweepStakes ? 'entries' : 'coins'"
				:iconSize="40"
				:img="`/nuxt-img/cards/${isSweepStakes ? 'entries' : 'coins'}.png`"
			>
				<AText variant="tanta" :modifiers="['semibold']">
					{{ numberFormat(parseInt(race?.prize ?? "0")) }}
				</AText>
			</MPrizeFund>
		</div>

		<AText class="text-conakry">
			<template v-if="!race?.isActive">
				{{ t("Starts") }}&nbsp; <b class="text-cannes">{{ dayjs(race.start).format("h:mm A") }}</b>
			</template>
			<template v-else-if="duration > 0">
				{{ t("Time Left") }}&nbsp;
				<b class="text-cannes">{{ durationLeft.replace(/00h|(\d+h)\s(\d+m)\s(\d+s)/gi, "$1 $2") }}</b>
			</template>
		</AText>

		<AButton variant="primary">
			<AText variant="toledo" :modifiers="['uppercase', 'semibold']">
				{{ race.isSubscribed ? t("continue racing") : t("join now for free") }}
			</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.activity-race.item {
	justify-content: space-between;
	padding-top: gutter(5.25);

	@include media-breakpoint-down(md) {
		padding: gutter(14) 0 gutter(1.5);
		align-items: center;
	}

	.image {
		position: absolute;
		right: 0;
		top: 0;
		width: 163px;
		height: 170px;

		@include media-breakpoint-down(md) {
			width: 100%;
			object-fit: cover;
			bottom: 65px;
		}
	}

	.badge {
		position: absolute;
		left: 0;
		top: 0;
		color: var(--caint);
	}

	&.is-entries .badge {
		color: var(--cavan);
	}

	button {
		min-width: 165px;
		width: max-content;

		@include media-breakpoint-down(md) {
			min-width: 135px;

			> span {
				font-size: 13px;
			}
		}
	}

	.prize-pool {
		display: flex;
		align-items: center;
		z-index: 1;

		.prize {
			gap: gutter(0.5);

			&:deep(img) {
				width: 24px;
				height: 24px;

				@include media-breakpoint-down(md) {
					width: 20px;
					height: 20px;
				}
			}
		}

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
			justify-content: center;
			gap: gutter(0.5);

			span.tanta {
				font-size: 16px;
			}
		}

		.label {
			max-width: 36px;
			margin-right: gutter(1);

			@include media-breakpoint-down(md) {
				margin-right: 0;
				max-width: none;
				width: 100%;
				text-align: center;
			}
		}
	}
}
</style>
